import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import Room from '@material-ui/icons/Room'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H3 from '../components/H3'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import ConverseComEspecialistas from '../images/contato/converse-com-nossos-especialistas.png'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledContact,
  header,
  contactInfo,
  contactItem,
  imagem,
  contactForm,
  termosBox 
} from '../components/styles/Contact.styles'
import ContactForm from '../components/ContactForm2'
import { validateRequiredEmail } from '../utils/validations';

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Assunto',
    name: 'your-subject',
    type: 'text',
    required: true
  },
  {
    label: 'Departamento',
    name: 'department',
    type: 'select',
    options: [
      { value: 1, label: 'Suporte' },
      { value: 2, label: 'Financeiro' },
      { value: 3, label: 'Vendas' }
    ],
    required: true
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledContact}>
      <Container>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={header}
        >
          <Column
            width={[1, 1, 1/2, 1/2]}
            order={[2, 2, 1, 1]}
          >
            <div >
              <img css={imagem} src={ConverseComEspecialistas} />
            </div>
          </Column>
          <Column
            width={[1, 1, 1/2, 1/2]}
            order={[1, 1, 2, 2]}
            mt={[0, 0, 32, 32]}
          >
            <H3>Converse com nossos especialistas.</H3>
            <Paragraph>Envie a sua dúvida ou sugestão através do formulário abaixo ou entre em contato através do nosso e-mail, telefone ou live chat. Estamos sempre disponíveis para ouvir você.</Paragraph>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={contactInfo}
          mt={[40, 40, 50, 50]}
        >
          <Column
            order={[2, 2, 1, 1]}
            width={[1, 1, 1/2, 1/2]}
            mt={[90, 50, 0, 0]}
          >
            <div css={contactItem}>
              <Room
                style={{
                  color: '#ff5800',
                  fontSize: 32
                }}
              />
              <H4>João Pessoa</H4>
              <Paragraph>Av. São Paulo, 1223<br /> João Pessoa - PB - Brasil<br /> CEP: 58.030-040</Paragraph>
            </div>
            <div css={contactItem}>
              <Room
                style={{
                  color: '#ff5800',
                  fontSize: 32
                }}
              />
              <H4>São Paulo</H4>
              <Paragraph>Av. Brigadeiro Faria Lima, 3.729, 5º Andar <br />São Paulo - SP - Brasil <br />CEP: 04538-905</Paragraph>
            </div>
            <div css={contactItem}>
              <Room
                style={{
                  color: '#ff5800',
                  fontSize: 32
                }}
              />
              <H4>Orlando - USA</H4>
              <Paragraph>189 South Orange Ave<br /> Suite 1500 <br />Orlando, Florida 32801</Paragraph>
            </div>
          </Column>
          <Column
            order={[1, 1, 2, 2]}
            width={[1, 1, 1/2, 1/2]}
          >
            <div css={contactForm}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <ContactForm
                  fields={fields}
                  formId={4452}
                  submitText='Enviar Mensagem'
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Entre em contato | HostDime Brasil' 
        description='Nossos desenvolvedores de negócios e analistas técnicos a um clique de distância. Compartilhe suas dúvidas sobre nossos produtos e receba o contato de nosso time.' 
      />
      <PageTemplate
        title='Contato'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
